var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[(_vm.error)?_c('v-card-text',{staticClass:"mb-0"},[_c('v-alert',{staticClass:"mb-0",attrs:{"dense":"","outlined":"","border":"left","type":"error"}},[_vm._v(" "+_vm._s(_vm.error.message)+" ")])],1):_vm._e(),_c('v-card-text',[_c('m-widget-container-form',{model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('v-text-field',{attrs:{"outlined":"","label":"Title","hint":"Leave blank to disable title"},model:{value:(_vm.value.title),callback:function ($$v) {_vm.$set(_vm.value, "title", $$v)},expression:"value.title"}}),_c('m-array-form',{attrs:{"name":"News Item","item-title":"title"},scopedSlots:_vm._u([{key:"item.subtitle",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date,'DD.MM.YYYY'))+" ")]}},{key:"default",fn:function(ref){
var editItem = ref.editItem;
return [_c('validation-provider',{attrs:{"rules":"required","name":"Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Title","required":"","error-messages":errors},model:{value:(editItem.title),callback:function ($$v) {_vm.$set(editItem, "title", $$v)},expression:"editItem.title"}})]}}],null,true)}),_c('m-date-form',{attrs:{"label":"Date Published"},model:{value:(editItem.date),callback:function ($$v) {_vm.$set(editItem, "date", $$v)},expression:"editItem.date"}}),_c('validation-provider',{attrs:{"rules":"required","name":"Content"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Content","auto-grow":"","required":"","outlined":"","error-messages":errors},model:{value:(editItem.content),callback:function ($$v) {_vm.$set(editItem, "content", $$v)},expression:"editItem.content"}})]}}],null,true)})]}}],null,true),model:{value:(_vm.value.items),callback:function ($$v) {_vm.$set(_vm.value, "items", $$v)},expression:"value.items"}}),_c('field-card',{attrs:{"label":"Card Design"}},[_c('m-color-input',{attrs:{"label":"Background Color"},model:{value:(_vm.value.cardColor),callback:function ($$v) {_vm.$set(_vm.value, "cardColor", $$v)},expression:"value.cardColor"}}),_c('v-row',{staticClass:"flex-wrap"},[_c('v-switch',{staticClass:"mx-5",attrs:{"label":"Dark Mode"},model:{value:(_vm.value.cardDark),callback:function ($$v) {_vm.$set(_vm.value, "cardDark", $$v)},expression:"value.cardDark"}}),_c('v-switch',{staticClass:"mx-5",attrs:{"label":"Shaped Style"},model:{value:(_vm.value.cardShaped),callback:function ($$v) {_vm.$set(_vm.value, "cardShaped", $$v)},expression:"value.cardShaped"}}),_c('v-switch',{staticClass:"mx-5",attrs:{"label":"No Shadows"},model:{value:(_vm.value.cardFlat),callback:function ($$v) {_vm.$set(_vm.value, "cardFlat", $$v)},expression:"value.cardFlat"}})],1)],1),_c('field-card',{attrs:{"label":"Dialog Design"}},[_c('m-color-input',{attrs:{"label":"Background Color"},model:{value:(_vm.value.dialogColor),callback:function ($$v) {_vm.$set(_vm.value, "dialogColor", $$v)},expression:"value.dialogColor"}}),_c('v-row',{staticClass:"flex-wrap"},[_c('v-switch',{staticClass:"mx-5",attrs:{"label":"Dark Mode"},model:{value:(_vm.value.dialogDark),callback:function ($$v) {_vm.$set(_vm.value, "dialogDark", $$v)},expression:"value.dialogDark"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }