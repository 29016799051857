<template>
  <v-card flat>
    <v-card-text
      v-if="error"
      class="mb-0"
    >
      <v-alert
        dense
        outlined
        border="left"
        type="error"
        class="mb-0"
      >
        {{ error.message }}
      </v-alert>
    </v-card-text>
    <v-card-text>
      <m-widget-container-form v-model="value" />
      <v-text-field
        v-model="value.title"
        outlined
        label="Title"
        hint="Leave blank to disable title"
      />
      <m-array-form
        v-model="value.items"
        name="News Item"
        item-title="title"
      >
        <template #[`item.subtitle`]="{ item }">
          {{ item.date | formatDate('DD.MM.YYYY') }}
        </template>
        <template #default="{ editItem }">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Title"
          >
            <v-text-field
              v-model="editItem.title"
              outlined
              label="Title"
              required
              :error-messages="errors"
            />
          </validation-provider>
          <m-date-form
            v-model="editItem.date"
            label="Date Published"
          />
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Content"
          >
            <v-textarea
              v-model="editItem.content"
              label="Content"
              auto-grow
              required
              outlined
              :error-messages="errors"
            />
          </validation-provider>
        </template>
      </m-array-form>
      <field-card label="Card Design">
        <m-color-input
          v-model="value.cardColor"
          label="Background Color"
        />
        <v-row class="flex-wrap">
          <v-switch
            v-model="value.cardDark"
            class="mx-5"
            label="Dark Mode"
          />
          <v-switch
            v-model="value.cardShaped"
            class="mx-5"
            label="Shaped Style"
          />
          <v-switch
            v-model="value.cardFlat"
            class="mx-5"
            label="No Shadows"
          />
        </v-row>
      </field-card>
      <field-card label="Dialog Design">
        <m-color-input
          v-model="value.dialogColor"
          label="Background Color"
        />
        <v-row class="flex-wrap">
          <v-switch
            v-model="value.dialogDark"
            class="mx-5"
            label="Dark Mode"
          />
        </v-row>
      </field-card>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'MNewsSliderForm',
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    error: null
  })
}
</script>
